import React from 'react'
import {Link} from 'react-router-dom'

const HomeChoiceButton = ({to, src, alt, children}) => 
	<Link to={to} className="main-choice">
		<div className="choice-img">
			<img src={src} alt={alt} />
		</div>
		<div className="choice-infos">
			{children}
		</div>
	</Link>

export default HomeChoiceButton