import React from 'react';
import {
  Link,
} from 'react-router-dom'

import illuCoche from '../../../../assets/images/svg-coche.svg';
import illuServiette from '../../../../assets/images/illu-serviette.png';

class ProductWomanTwo extends React.Component {
	componentDidMount() {
		document.body.classList.add('femme');
	}

	componentWillUnmount() {
		document.body.classList.remove('femme');
	}

	render() {
		return(
			<div className="content bg-pink">
				<div className="main-wrapper">
					<div className="main-forms main-forms-echantillon">
						<div className="main-forms-inner">
							<h1 className='title'>Serviettes :</h1>
							<div className="main-form">
								<div className="form-column">
									<div className="bulle active"><img src={illuCoche} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCoche} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCoche} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCoche} alt="Illustration coche" /></div>
								</div>
								<div className="form-inputs">
									<div className="form-info"><p>Plus épaisses qu’un protège-slip, les serviettes pour fuites urinaires assurent une plus grande capacité d’absorption pour des fuites légères à modérées. Elles existent en différentes absorptions : de “mini” à “maxi night” (suivre le nombre de gouttes)</p></div>
									<Link to="/recevoir-des-echantillons" className="form-btn">
										<div className="form-btn-left">
											<p>Recevoir des échantillons</p>
											<span>Cliquer ici</span>
										</div>
										<div className="form-btn-right">
											<img src={illuServiette} alt="Illustration serviette" />
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProductWomanTwo
