import React from 'react';

function Footer() {
	return (
		<footer className="main-footer">
			<div className="footer-links">
				<a target="_blank" rel="noopener noreferrer" href="https://www.tena.fr/conditions-generales" title="Mentions légales">Mentions légales</a> | <a target="_blank" rel="noopener noreferrer" href="https://www.tena.fr/politique-de-confidentialite" title="Politique de confidentialité">Politique de confidentialité</a> | <a target="_blank" rel="noopener noreferrer" href="https://www.tena.fr/cookies" title="Cookies">Cookies</a>
			</div>
		</footer>
	);
}

export default Footer
