import React from 'react';
import {
  Link,
} from 'react-router-dom'

import illuCocheBleue from '../../../../assets/images/svg-coche-bleue.svg';

class ProductManOne extends React.Component {
	componentDidMount() {
		document.body.classList.add('homme');
	}

	componentWillUnmount() {
		document.body.classList.remove('homme');
	}

	render() {
		return(
			<div className="content bg-blue">
				<div className="main-wrapper">
					<div className="main-forms main-forms-echantillon">
						<div className="main-forms-inner">
							<h1 className='title'>Sous-vêtements :</h1>
							<div className="main-form">
								<div className="form-column">
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
								</div>
								<div className="form-inputs">
									<div className="form-info"><p>Les sous-vêtements existent en version unisexes et masculins.</p></div>
									<Link to="/recevoir-des-echantillons-homme" className="form-btn">
										<div className="form-btn-left">
											<p>Recevoir des échantillons</p>
											<span>Cliquer ici</span>
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProductManOne


