import React from 'react';

import Carousel from 'react-elastic-carousel'

import imgEchantillons from '../../../assets/images/echantillons.png';

class ChoiceTwo extends React.Component {
	render() {
		return(
			<div className="content bg-white">
				<div className="main-wrapper">
					<div className="main-tabs main-tabs-2" >
						<div className="main-tabs-inner" >
							<div className="main-tab-inner">
								<h1 className='title'><span>Nos conseils </span>pour vivre avec des fuites urinaires</h1>
								<div className="main-tab">
									<Carousel itemsToShow={1}
										showArrows={false}>
										<div className="main-tab-wrapper main-tab-1">
											<div className="tab-title">
												<p>Entretenir son périnée</p>
												<span>1</span>
											</div>
											<div className="tab-infos">
												<p>Le périnée est un ensemble très solide de muscles, de ligaments et membranes situés sous la vessie. Il a 3 fonctions : il soutient les organes, agit sur la continence et participe à l’activité sexuelle. Tout au long de la vie d’une femme, le périnée subit des modifications et perd de sa tonicité. Il a alors du mal a excercer sa fonction de soutien. <small>Retrouvez nos vidéos pour apprendre à muscler son périnée sur tena.fr</small></p>
											</div>
										</div>
										<div className="main-tab-wrapper main-tab-2">
											<div className="tab-title">
												<p>S’hydrater</p>
												<span>2</span>
											</div>
											<div className="tab-infos">
												<p>- Etaler vos consommations de boissons sur toutes la durée de la journée (1 verre toutes les 4 heures)<br/>- Eviter de boire de grosses quantités sur une courte période<br/>- Eviter l’alcool et les boissons décaféinées qui sont des diurétiques qui contribuent à une urination et à des fuites plus fréquentes.</p>
											</div>
										</div>
										<div className="main-tab-wrapper main-tab-3">
											<div className="tab-title"></div>
											<div className="tab-infos">
												<p><b>Pour plus d'informations, <br/>rendez-vous sur</b></p><a href="https://www.tena.fr/" target="_blank" rel="noopener noreferrer" >www.tena.fr</a>
											</div>
										</div>
									</Carousel>
								</div>
							</div>
						</div>

						<div className="echantillon">
							<a target="_blank" rel="noopener noreferrer" href="https://www.tena.fr/echantillon-gratuit" title="Demandez un échantillon gratuit">Demandez un <span>échantillon gratuit</span></a>
							<img src={imgEchantillons} alt="Échantillons" />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ChoiceTwo

