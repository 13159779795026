import React from 'react';
import {
  Link,
} from 'react-router-dom'

import illuCoche from '../../../../assets/images/svg-coche.svg';
import illuCulotte from '../../../../assets/images/illu-culotte.png';

class ProductWomanThree extends React.Component {
	componentDidMount() {
		document.body.classList.add('femme');
	}

	componentWillUnmount() {
		document.body.classList.remove('femme');
	}

	render() {
		return(
			<div className="content bg-pink">
				<div className="main-wrapper">
					<div className="main-forms main-forms-echantillon">
						<div className="main-forms-inner">
							<h1 className='title'>Culottes :</h1>
							<div className="main-form">
								<div className="form-column">
									<div className="bulle active"><img src={illuCoche} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCoche} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCoche} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCoche} alt="Illustration coche" /></div>
								</div>
								<div className="form-inputs">
									<div className="form-info"><p>Les culottes absorbantes jetables se portent comme un sous-vêtement classique et existent en différentes coupes et couleurs pour s’adapter à vos goûts et vos tenues.<br/>Les culottes existent en version unisexes et féminines.</p></div>
									<Link to="/recevoir-des-echantillons" className="form-btn">
										<div className="form-btn-left">
											<p>Recevoir des échantillons</p>
											<span>Cliquer ici</span>
										</div>
										<div className="form-btn-right">
											<img src={illuCulotte} alt="Illustration culotte" />
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProductWomanThree
