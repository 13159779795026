import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

import './app.scss';

// commons
import Header from '../commons/header';
import Footer from '../commons/footer';
import CookieBanner from '../commons/cookieBanner';
// home
import Home from '../pages/home';
// pages principales
import ButtonOne from '../pages/buttonOne';
import ButtonTwo from '../pages/buttonTwo';
import ButtonThree from '../pages/buttonThree';
// sous-page select femme
import StepOneWoman from '../pages/buttonThree/components/StepOneWoman';
import StepTwoWoman from '../pages/buttonThree/components/StepTwoWoman';
import StepThreeWoman from '../pages/buttonThree/components/StepThreeWoman';
// sous-page produit femme
import ProductWomanOne from '../pages/buttonThree/components/ProductWomanOne';
import ProductWomanTwo from '../pages/buttonThree/components/ProductWomanTwo';
import ProductWomanThree from '../pages/buttonThree/components/ProductWomanThree';
// sous-page select homme
import StepOneMan from '../pages/buttonThree/components/StepOneMan';
import StepTwoMan from '../pages/buttonThree/components/StepTwoMan';
import StepThreeMan from '../pages/buttonThree/components/StepThreeMan';
// sous-page produit homme
import ProductManOne from '../pages/buttonThree/components/ProductManOne';
import RedirectEchantillonFemme from '../pages/buttonThree/components/RedirectEchantillonFemme';
import RedirectEchantillonHomme from '../pages/buttonThree/components/RedirectEchantillonHomme';
// page de redirection
import ButtonFour from '../pages/buttonFour';

class App extends Component {
  render() {

	return (
	  <Router>
		<Header />
		<CookieBanner />

			<Route exact path='/' component={Home} />

			<Route path="/" render={({location}) => {
			  if (typeof window.ga === 'function') {
				window.ga('set', 'page', location.pathname + location.search);
				window.ga('send', 'pageview');
			  }
			  return null;
			}} />
			<Route path='/fuites-urinaire' component={ButtonOne} />

			<Route path='/nos-conseils' component={ButtonTwo} />

			<Route path='/comment-choisir' component={ButtonThree} />
			<Route path='/recevoir-des-echantillons' component={RedirectEchantillonFemme} />
			<Route path='/recevoir-des-echantillons-homme' component={RedirectEchantillonHomme} />

			<Route path='/femme/comment-choisir-etape-1' component={StepOneWoman} />
			<Route path='/femme/comment-choisir-etape-2' component={StepTwoWoman} />
			<Route path='/femme/comment-choisir-etape-3' component={StepThreeWoman} />

			<Route path='/femme/proteges-slips' component={ProductWomanOne} />
			<Route path='/femme/serviettes' component={ProductWomanTwo} />
			<Route path='/femme/culottes' component={ProductWomanThree} />

			<Route path='/homme/comment-choisir-etape-1' component={StepOneMan} />
			<Route path='/homme/comment-choisir-etape-2' component={StepTwoMan} />
			<Route path='/homme/comment-choisir-etape-3' component={StepThreeMan} />
			<Route path='/homme/sous-vetements' component={ProductManOne} />

			<Route path='/echantillon-gratuit' component={ButtonFour} />
		<Footer />
	  </Router>
	)
  }
}

export default App
