import React from 'react'

import HomeChoiceButton from '../home/components/HomeChoiceButton'

import pictoGoutte from '../../../assets/images/svg-goutte.svg';
import pictoConseil from '../../../assets/images/svg-conseil.svg';
import pictoProtection from '../../../assets/images/svg-protection.svg';
import pictoEchantillon from '../../../assets/images/svg-echantillon.svg';

const Home = () => (
	<div className="content bg-white">
		<div className="main-wrapper">
			<div className="main-choices">
				<HomeChoiceButton to="/fuites-urinaire" src={pictoGoutte} alt="Picto fuites urinaires">
					<p>Qu'est-ce que les <span>fuites urinaires ?</span></p>
				</HomeChoiceButton>

				<HomeChoiceButton to="/nos-conseils" src={pictoConseil} alt="Picto nos conseils">
					<p><span>Nos conseils </span>pour vivre avec des fuites urinaires</p>
				</HomeChoiceButton>

				<HomeChoiceButton to="/comment-choisir" src={pictoProtection} alt="Picto protections">
					<p>Comment choisir votre produit pour <span>fuites urinaires ?</span></p>
				</HomeChoiceButton>

				<HomeChoiceButton to="/echantillon-gratuit" src={pictoEchantillon} alt="Picto échantillon">
					<p>Demandez un <span>échantillon gratuit</span></p>
				</HomeChoiceButton>
			</div>
		</div>
	</div>
  )

export default Home
