import React from 'react';
import {
  Link,
} from 'react-router-dom'

import illuCocheBleue from '../../../../assets/images/svg-coche-bleue.svg';

class StepOneMan extends React.Component {
	componentDidMount() {
		document.body.classList.add('homme');
	}

	componentWillUnmount() {
		document.body.classList.remove('homme');
	}

	render() {
		return(
			<div className="content bg-blue">
				<div className="main-wrapper">
					<div className="main-forms main-forms-1">
						<div className="main-forms-inner">
							<h1 className='title'>Vous êtes ?</h1>
							<div className="main-form">
								<div className="form-column">
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle"><img src={illuCocheBleue} alt="Illustration coche" /></div>
								</div>
								<div className="form-inputs">

								<Link to='/femme/comment-choisir-etape-2'><div className="form-input single-line-input">une femme</div></Link>
								<Link to='/homme/comment-choisir-etape-2'><div className="form-input single-line-input">un homme</div></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default StepOneMan

