import React from 'react';
import {
  Link,
} from 'react-router-dom'

import illuCocheBleue from '../../../../assets/images/svg-coche-bleue.svg';

class StepTwoWoman extends React.Component {
	componentDidMount() {
		document.body.classList.add('homme');
	}

	componentWillUnmount() {
		document.body.classList.remove('homme');
	}

	render() {
		return(
			<div className="content bg-blue">
				<div className="main-wrapper">
					<div className="main-forms">
						<div className="main-forms-inner">
							<h1 className='title'>À quelle fréquence <br/>ai-je des fuites ?</h1>
							<div className="main-form">
								<div className="form-column">
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
								</div>
								<div className="form-inputs">
									<Link to='/homme/comment-choisir-etape-3'><div className="form-input multiple-line-input"><b>Occasionnellement </b> après un effort physique</div></Link>
									<Link to='/homme/comment-choisir-etape-3'><div className="form-input multiple-line-input"><b>De temps en temps </b> lorsque j'éternue, je tousse, je ris</div></Link>
									<Link to='/homme/comment-choisir-etape-3'><div className="form-input single-line-input"><b>1 fois par jour</b></div></Link>
									<Link to='/homme/comment-choisir-etape-3'><div className="form-input single-line-input"><b>Plusieurs fois par jour</b></div></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default StepTwoWoman
