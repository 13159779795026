import React from 'react';

import Carousel from 'react-elastic-carousel'

import imgEchantillons from '../../../assets/images/echantillons.png';

class ChoiceOne extends React.Component {
	render() {
		return(
			<div className="content bg-white">
				<div className="main-wrapper">
					<div className="main-tabs main-tabs-1" >
						<div className="main-tabs-inner" >
							<div className="main-tab-inner main-tab-inner-1">
								<h1 className='title'>Qu’est-ce que les <span>fuites urinaires ?</span></h1>
								<div className="main-tab">
									<Carousel itemsToShow={1}
										showArrows={false}>
										<div className="main-tab-wrapper">
											<div className="tab-title">
												<p>Il existe 3 types d’incontinence</p>
												<span>1</span>
											</div>
											<div className="tab-infos">
												<p><b>L’incontinence liée à l’effort </b>est la plus fréquente chez la femme. Une forte toux, un fou rire, un éternuement, le transport d’un objet lourd et une petite fuite urinaire s’échappe... C’est le résultat d’un périnée qui a perdu son tonus.</p>
											</div>
										</div>
										<div className="main-tab-wrapper">
											<div className="tab-title">
												<p>Il existe 3 types d’incontinence</p>
												<span>2</span>
											</div>
											<div className="tab-infos">
												<p><b>L’incontinence par impériosité, </b>qui se caractérise par un besoin urgent d’aller aux toilettes sans pouvoir se retenir. Il s’agit d’un dysfonctionnement de la vessie qui se contracte trop tôt et sans raison. Elle peut être due à de l'anxiété, à la prise de certains médicaments ou simplement à l’âge.</p>
											</div>
										</div>
										<div className="main-tab-wrapper">
											<div className="tab-title">
												<p>Il existe 3 types d’incontinence</p>
												<span>3</span>
											</div>
											<div className="tab-infos">
												<p><b>L’incontinence mixte, </b>qui associe les deux types d’incontinence ci-dessus.</p>
											</div>
										</div>
									</Carousel>
								</div>
							</div>
						</div>

						<div className="echantillon">
							<a target="_blank" rel="noopener noreferrer" href="https://www.tena.fr/echantillon-gratuit" title="Demandez un échantillon gratuit">Demandez un <span>échantillon gratuit</span></a>
							<img src={imgEchantillons} alt="Échantillons" />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ChoiceOne

