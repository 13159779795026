import React from 'react';
import {
	 Link,
} from 'react-router-dom'

import logoClassic from '../../../assets/images/logo-en_toute_intimite.svg';
import logoPink from '../../../assets/images/logo-en_toute_intimite-pink.svg';
import logoBlue from '../../../assets/images/logo-en_toute_intimite-blue.svg';

class Header extends React.Component {
	render() {
		return (
			<header className="main-header">
				<Link className="header-logo logo" to="/" exact >
					<img className="header-classic" src={logoClassic} alt="Logo En toute intimité by Tena" />
					<img className="header-femme" src={logoPink} alt="Logo En toute intimité by Tena" />
					<img className="header-homme" src={logoBlue} alt="Logo En toute intimité by Tena" />
				</Link>
			</header>
		);
	}
}

export default Header
