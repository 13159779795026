import React from 'react';
import {
  Link,
} from 'react-router-dom'

import illuCocheBleue from '../../../../assets/images/svg-coche-bleue.svg';
import illuGoutteVideBleue from '../../../../assets/images/svg-goutte-vide-bleue.svg';
import illuGouttePleineBleue from '../../../../assets/images/svg-goutte-pleine-bleue.svg';

class StepThreeWoman extends React.Component {
	componentDidMount() {
		document.body.classList.add('homme');
	}

	componentWillUnmount() {
		document.body.classList.remove('homme');
	}

	render() {
		return(
			<div className="content bg-blue">
				<div className="main-wrapper">
					<div className="main-forms">
						<div className="main-forms-inner">
							<h1 className='title'>Comment qualifieriez-vous <br/>vos fuites urinaires ?</h1>
							<div className="main-form">
								<div className="form-column">
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle active"><img src={illuCocheBleue} alt="Illustration coche" /></div>
									<div className="bulle"><img src={illuCocheBleue} alt="Illustration coche" /></div>
								</div>
								<div className="form-inputs">
									<Link to="/recevoir-des-echantillons-homme" className="form-input multiple-line-input">
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
									<span>(protections discrètes)</span>
									</Link>
									<Link to="/recevoir-des-echantillons-homme" className="form-input multiple-line-input">
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<span>(protections absorbantes homme)</span>
									</Link>
									<Link to="/homme/sous-vetements" className="form-input multiple-line-input">
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGouttePleineBleue} alt="Illustration goutte pleine" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<img src={illuGoutteVideBleue} alt="Illustration goutte vide" />
										<span>(sous-vêtements homme)</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default StepThreeWoman


