import React from 'react';

import StepOneWoman from '../buttonThree/components/StepOneWoman'

class ChoiceThree extends React.Component {
	render() {
		return(
			<StepOneWoman />
		)
	}
}

export default ChoiceThree

