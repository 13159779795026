import React from 'react';
import illuCookie from '../../../assets/images/svg-cookie.svg';

import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class CookieBanner extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hide: false // value hide seton false
		}
	}

	AcceptCookieBanner(value) {
		this.setState({
			hide: value // get value set while onClick
		});

		cookies.set('CookieBanner', 'Cookie-ok', { path: '/' });
		ReactGA.initialize('UA-162889205-2');
	}

	LoadCookie(){
		ReactGA.initialize('UA-162889205-2');
	}

	render() {
		if(cookies.get('CookieBanner')){
			return (
				<div>
					 {this.LoadCookie()}
				</div>
			);
		  }
		else{
			return (
				<div className="cookie-banner">
					<img src={illuCookie} alt="Illustration cookie" />
					<p>Nous utilisons des cookies pour permettre de mieux comprendre comment le site est utilisé. En cliquant sur accepter, vous acceptez l'utilisation de ces derniers.</p>
					<button onClick={() => this.AcceptCookieBanner(true)}>J'accepte</button>
				</div>
			);
		}
	}
}

export default CookieBanner
