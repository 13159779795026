import React from 'react';

class RedirectEchantillon extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	componentDidMount() {
		this.interval = setInterval(() =>
			this.setState(
				window.location.replace('https://www.tena.fr/femmes/produits/echantillon-gratuit')
			), 3000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		return(
			<div className="content bg-white redirection">
				<div className="main-wrapper">
					<div className="main-forms main-forms-echantillon">
						<h1 className='title'>Pour votre demande d’échantillon <br/>vous allez être redirigé(e) vers le site internet <br/>www.tena.fr</h1>
						<p>Merci de votre confiance</p>
					</div>
				</div>
			</div>
		)
	}
}

export default RedirectEchantillon

